import { AxiosPromise } from 'axios';
import { WorkCalendar } from '@/types'; // Add the missing import statement
import axios from '@plugins/axios';

export const API_REF = 'primavera/work-calendars';

export async function getWorkCalendars(): AxiosPromise<WorkCalendar[]> {
    return axios.get(API_REF);
}

// export async function getWorkCenter(id: number): AxiosPromise<WorkCalendar> {
//     return axios.get(`${API_REF}/${id}`);
// }
