<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">Ordens de Fabrico</h1>
            </v-col>
            <!-- <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn

                    color="primary"
                    @click="$router.push({ name: 'CreateProductionOrder' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col> -->
        </v-row>
        <v-row
            id="events-search"
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start tw-bg-white tw-sticky tw-top-[var(--v-layout-top)] tw-z-[5] tw-mb-4">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-gap-2">
                <v-text-field
                    class="tw-grow max-sm:tw-w-full"
                    clearable
                    @update:model-value="onSearch"
                    placeholder="Pesquisar"></v-text-field>
                <!-- <v-btn

                    size="large"
                    density="comfortable"
                    class="text-gray tw-border tw-border-gray"
                    icon="mdi-tune"></v-btn> -->
                <!-- <v-btn


                    size="large"
                    density="comfortable"
                    class="text-gray  tw-mr-2 tw-border tw-border-gray"
                    icon="mdi-format-list-bulleted"></v-btn> -->
            </v-col>
        </v-row>
        <v-row>
            <v-data-table-server
                :headers="headers"
                :items="productionOrders"
                :items-length="total"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                @click:row="onRowClick"
                item-value="OrdemFabrico">
                <template v-slot:item.Descricao="{ item }">
                    <span>{{ description(item.Artigo) }}</span>
                </template>
                <template v-slot:item.DataOrdemFabrico="{ item }">
                    <span>{{ formatDate(item.DataOrdemFabrico) }}</span>
                </template>
                <template v-slot:item.Confirmada="{ item }">
                    <v-icon :color="item.Confirmada ? 'success' : 'error'">
                        {{ item.Confirmada ? 'mdi-check' : 'mdi-close' }}
                    </v-icon>
                </template>
                <template v-slot:item.Estado="{ item }">
                    <v-chip

                        size="default"
                        :color="item.Estado == 2 ? 'secondary' : item.Estado == 3 ? 'warning' : item.Estado == 4 ? 'error' : item.Estado == 5 ? 'success' : 'grey'"
                        dark>
                        {{ item.Estado == 2 ? 'Por Iniciar' : item.Estado == 3 ? 'Em Curso' : item.Estado == 4 ? 'Suspensa' : item.Estado == 5 ? 'Terminada' : 'Desconhecido' }}
                    </v-chip>
                </template>
            </v-data-table-server>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { WorkCenter } from '@/types';
    import { getProductionOrders } from '@/api/productionOrders';
    import { getArticles } from '@/api/articles';
    import { Ref, ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import _ from 'lodash';

    const { showLoader, hideLoader } = useLoader();
    const $router = useRouter();
    const $route = useRoute();

    const searchTimeoutId = ref<NodeJS.Timeout>();
    const search = ref('');
    const page = ref<number>(1);
    const total = ref<number>(0);
    const pageSize = ref<number>(10);

    const productionOrders = ref<any[]>([]);
    const articles = ref<any[]>([]);

    const headers = ref([
        { title: 'Ordem de Fabrico', key: 'OrdemFabrico' },
        { title: 'Artigo', key: 'Artigo' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Data', key: 'DataOrdemFabrico' },
        { title: 'Confirmada', key: 'Confirmada' },
        { title: 'Valorizada', key: '' },
        { title: 'Estado', key: 'Estado' },
    ]);

    async function onRowClick(row: any, o: any) {
        $router.push({ name: 'ProductionOrder', params: { id: o.item.IDOrdemFabrico } });
    }

    function onSearch(str: string) {
        clearTimeout(searchTimeoutId.value);
        searchTimeoutId.value = setTimeout(() => {
            search.value = str ?? '';
            setRouterQuery();
        }, 250);
    }

    function setRouterQuery() {
        const query = {
            ..._.cloneDeep($route.query),
            search: undefined as string | undefined,
        };
        if (search.value) query.search = search.value;

        $router.push({ query });
    }

    async function init() {
        showLoader();
        let _page, _pageSize;
        // if all are selected
        if (pageSize.value == -1) {
            (_page = null), (_pageSize = null);
        } else {
            _page = page.value;
            _pageSize = pageSize.value;
        }
        try {
            await getProductionOrders(_page, _pageSize, search.value)
                .then((response) => {
                    productionOrders.value = response.data.data;
                    total.value = response.data.total;
                })
                .finally(async () => {
                    const articlesIds = productionOrders.value.map((po) => po.Artigo);
                    const filterByIds = {
                        Artigo: articlesIds,
                    };
                    articles.value = (await getArticles(null, null, null, filterByIds)).data.data;
                    hideLoader();
                });
        } catch (error) {
            console.error(error);
        }
    }

    init();

    // watch when query changes
    watch(
        () => $route.query,
        () => {
            //search.value = $route.query.search as string ?? '';
            init();
        },
        {
            immediate: true,
        },
    );

    watch([page, pageSize], () => {
        console.log('page or pageSize changed');
        console.log(page.value, pageSize.value);
        init();
    });

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };

    // Return "Descricao" of an article
    const description = (articleId: string) => {
        const article = articles.value.find((a) => a.Artigo == articleId);
        return article?.Descricao ?? '';
    };
</script>

<style scoped></style>
