import { AxiosPromise } from 'axios';
//import { WorkCenter } from '@/types'; // Add the missing import statement
import axios from '@plugins/axios';

export const API_REF = 'primavera/families';

export async function getFamilies(page: number | null = null, limit: number | null = null, search: string | null = null): AxiosPromise<any[]> {
    return axios.get(API_REF, {
        params: {
            page,
            limit,
            search
        },
    });
}

export async function getFamily(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}
