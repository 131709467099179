<template>
    <v-banner v-if="needRefresh" color="primary" density="compact" position="fixed" location="bottom left" elevation="24">
        <template #text> {{ $t('promptUpdate.text') }} </template>
        <template #actions>
            <v-btn  height="2rem" @click="close">{{ $t('promptUpdate.later') }}</v-btn>
            <v-btn  class="tw-ml-2" variant="flat" height="2rem" color="primary" @click="updateServiceWorker">{{ $t('promptUpdate.update') }}</v-btn>
        </template>
    </v-banner>
</template>

<script setup lang="ts">
    import { useRegisterSW } from 'virtual:pwa-register/vue';
    import { pwaInfo } from 'virtual:pwa-info';

    const reloadSW: any = true;

    const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
        immediate: true,
        async onRegisteredSW(swUrl, r) {
            if (reloadSW && r) {
                await r?.update();
            }
        },
    });

    async function close() {
        offlineReady.value = false;
        needRefresh.value = false;
    }
</script>

<style scoped></style>
