<template>
    <h1 class="auth-title">{{ $t('auth.resetPassword') }}</h1>
    <v-form
        ref="form"
        @submit.prevent="onSubmit"
        class="auth-form"
        action="">
        <v-text-field
            v-model="password"
            class="auth-input"
            name="new-password"
            :rules="[rules.required, (e) => rules.min(e, 6)]"
            :type="isPasswordVisible ? 'text' : 'password'"
            :placeholder="$t('fields.newPassword')"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>
        <v-text-field
            v-model="passwordConfirmation"
            class="auth-input"
            name="new-password"
            :rules="[(e) => rules.comparePassword(e, password), rules.required, (e) => rules.min(e, 6)]"
            :type="isPasswordVisible ? 'text' : 'password'"
            :placeholder="$t('fields.confirmPassword')"
            :append-inner-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append-inner="isPasswordVisible = !isPasswordVisible"></v-text-field>
        <div v-if="alertMessage">
            <v-alert
                :type="alertType"
                variant="tonal"
                density="compact"
                >{{ alertMessage }}</v-alert
            >
        </div>

        <v-btn

            :loading="isLoading"
            type="submit"
            color="primary"
            class="auth-btn"
            >{{ $t('auth.changePassword') }}</v-btn
        >
    </v-form>
</template>

<script setup lang="ts">
    import axios from '@axios';
    import useRules from '@/composables/rules';
    import { ref } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useI18n } from 'vue-i18n';
    import { useRoute, useRouter } from 'vue-router';
    import { useError } from '@/composables/useError';

    const $t = useI18n().t;
    const $route = useRoute();
    const $router = useRouter();
    const $error = useError();

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);

    const password = ref('');
    const passwordConfirmation = ref('');
    const isPasswordVisible = ref(false);
    const alertMessage = ref('');
    const alertType = ref<'success' | 'error'>('success');

    async function onSubmit() {
        if (!form.value) return;

        alertMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            const { data } = await axios.post('/auth/reset-password', {
                password: password.value,
                passwordConfirmation: passwordConfirmation.value,
                code: $route.params.token,
            });

            alertMessage.value = $t('auth.resetPasswordSuccess');
            alertType.value = 'success';
            setTimeout(() => {
                $router.push({ name: 'Login' });
            }, 2000);
        } catch (error) {
            alertMessage.value = $error.getErrorMessage(error, 'auth.errors');
            alertType.value = 'error';
        } finally {
            isLoading.value = false;
        }
    }
</script>

<style scoped></style>
