<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">Importador AutoDesk Inventor</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn
                    prepend-icon="mdi-download"
                    color="secondary"
                    @click="downloadTemplate"
                    >{{ $t('shared.downloadTemplate') }}</v-btn
                >
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-file-input
                    chips
                    @update:modelValue="fileChangeXLSX"
                    accept=".xlsx"
                    label="Ficha Técnica"></v-file-input>
            </v-col>
        </v-row>
        <v-row>
            <div class="tw-flex tw-w-full tw-items-end tw-justify-end">
                <v-btn

                    @click="sendTechnicalSheet"
                    type="button"
                    :disabled="!technicalSheetToSend || !mainArticle || isRequested"
                    color="primary">
                    Enviar Ficha Técnica
                </v-btn>
            </div>
        </v-row>
        <v-row v-if="technicalSheetToSend">
            <div class="hot-table-container tw-mt-3 tw-w-full tw-overflow-x-auto">
                <hot-table
                    :data="technicalSheetToSend"
                    :licenseKey="license"
                    :rowHeaders="false"
                    :stretchH="'none'"
                    :width="'100%'"
                    :colHeaders="headers1"
                    :readOnly="true"></hot-table>
            </div>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { parse } from 'csv-parse/browser/esm/sync';
    import * as XLSX from 'xlsx';
    import { ref, computed } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { createTechnicalSheet } from '@/api/technicalSheets';
    import { useLoader } from '@/composables/useLoader';
    import { getInventorTechnicalSheetTemplate } from '@/api/inventorTechnicalSheetTemplate';

    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';
    import Handsontable from 'handsontable';

    registerAllModules();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);

    const { showLoader, hideLoader } = useLoader();
    // variables to build treeview
    const technicalInfo = ref<any | null>(null);
    const acquiredInfo = ref<any | null>(null);
    const isRequested = ref<boolean>(false);

    // variables to send to backend
    const technicalSheetToSend = ref<any | null>(null);
    const acquiredSheetToSend = ref<any | null>(null);
    const mainArticle = ref<any | null>(null);

    const headers1 = ref<any | null>(null);

    const acquiredRequiredFields = ['Cód.Adquirido', 'Designação Adquirido', 'Qt.', 'Unid. Consumo Adquirido'];
    const technicalRequiredFields = ['Num.', 'Cód.Material', 'Material', 'Qt. Consumida por peça', 'Qt. Consumida Total', 'Unid. Consumo Material'];

    const headers = ref([
        'Item',
        'Grupo Pertencente',
        'Part Number',
        'Description',
        'QTY',
        'Material',
        'Cód.Material',
        'Descricao Cod. Material',
        'Qt. Consumida por peça',
        'Desperdicio',
        'Medida Final',
        'Medida Corte',
        'Posto Inicial Produção',
        'Posto Seguinte 1',
        'Posto Seguinte 2',
        'Posto Seguinte 3',
        'Posto Seguinte 4',
        'Posto Seguinte 5',
        'Posto Seguinte 6',
        'Tratamento/ Acabamento Superficial'
    ]);

    const $alert = useAlert();

    const downloadTemplate = async () => {
        console.log('download template');

        try {
            const response = await getInventorTechnicalSheetTemplate();

            const data = response.data?.data?.data;

            const sorted = data.map((record: any) => {
                const sortedRecord = {} as any;
                headers.value.forEach((header) => {
                    sortedRecord[header] = record[header];
                });
                return sortedRecord;
            });

            const ws = XLSX.utils.json_to_sheet(sorted);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Ficha Técnica');
            XLSX.writeFile(wb, 'template.xlsx');

            //console.log(data);

            console.log(data);
        } catch (error) {
            console.log(error);

            $alert.showAlert({
                type: 'error',
                text: 'Erro ao descarregar template',
            });
            return;
        }

        //const data = [];
        // const data = [
        //     ['Num.', 'Cód.Material', 'Material', 'Qt. Consumida por peça', 'Qt. Consumida Total', 'Unid. Consumo Material'],
        //     ['1', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['2', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['3', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['4', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['5', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['6', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['7', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['8', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['9', 'Código Material', 'Material', '1', '1', 'Unidade'],
        //     ['10', 'Código Material', 'Material', '1', '1', 'Unidade'],
        // ];

        // const ws = XLSX.utils.json_to_sheet(data);
        // const wb = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Ficha Técnica');
        // XLSX.writeFile(wb, 'template.xlsx');
    };

    const fileChangeXLSX = async (file: File[]) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
            try {
                const data = new Uint8Array(e.target?.result as any);
                const workbook = XLSX.read(data, { type: 'array' });

                const componentsSheetName = workbook.SheetNames[0];

                const componentsSheet = workbook.Sheets[componentsSheetName];

                let jsonComponentsSheets = XLSX.utils.sheet_to_json(componentsSheet, { header: 1 });

                // remove empty rows
                jsonComponentsSheets = jsonComponentsSheets.filter((row: any) => row.join('').trim() != '');

                const headers: any = jsonComponentsSheets[0];
                let structuredComponents = jsonComponentsSheets.slice(1).map((row: any) => {
                    let obj: any = {};
                    headers.forEach((header: any, index: any) => {
                        obj[header] = row[index];
                    });
                    return obj;
                });

                // set all values to string
                structuredComponents = structuredComponents.map((component: any) => {
                    Object.keys(component).forEach((key) => {
                        if (typeof component[key] == 'number') {
                            //component[key] = component[key].toString();
                            component[key] = component[key];
                        } else if (typeof component[key] == 'undefined') {
                            component[key] = '';
                        }
                    });
                    return component;
                });

                headers1.value = Object.keys(JSON.parse(JSON.stringify(structuredComponents[0])));
                console.log(headers1.value);

                technicalSheetToSend.value = JSON.parse(JSON.stringify(structuredComponents));

                console.log(technicalSheetToSend.value);

                mainArticle.value = technicalSheetToSend.value.filter((component: any) => component['Item'] == '1' && component['QTY'] > 0)[0];
            } catch (error) {
                console.log(error);
                $alert.showAlert({
                    type: 'error',
                    text: 'Erro ao processar ficheiro',
                });
            }
        };
        reader.readAsArrayBuffer(file as any);
    };

    const sendTechnicalSheet = async () => {
        try {
            isRequested.value = true;
            showLoader();
            let data = {
                article: mainArticle.value['Part Number'],
                info: technicalSheetToSend.value,
                columns: headers1.value,
            };

            console.log(technicalSheetToSend.value);
            const response = await createTechnicalSheet(data);
            console.log(response);
            hideLoader();
            $alert.showAlert({
                type: 'success',
                text: 'Ficha técnica submetida com sucesso',
            });
        } catch (error) {
            hideLoader();
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao submeter ficha técnica',
            });
        } finally {
            isRequested.value = false;
        }
    };
</script>

<style scoped>
    .hot-table-container {
        height: 85vh;
    }
</style>
