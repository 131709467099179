import { AxiosPromise } from 'axios';
import { Section} from '@/types'; // Add the missing import statement
import axios from '@plugins/axios';

export const API_REF = 'primavera/sections';

export async function getSections(): AxiosPromise<Section[]> {
    return axios.get(API_REF);
}

// export async function getWorkCenter(id: number): AxiosPromise<Section> {
//     return axios.get(`${API_REF}/${id}`);
// }
