<template>
    <v-row class="tw-mt-3">
        <v-data-table
            :headers="headers"
            :items="components"
            v-model:page="page"
            v-model:items-per-page="pageSize"
            @click:row="onRowClick"
            item-value="Componente">
            <!-- Descrição -->
            <template v-slot:item.Descricao="{ item }">
                <span>{{ description(item.Componente) }}</span>
            </template>
            <!-- Unidade -->
            <template v-slot:item.Unidade="{ item }">
                <span>{{ unit(item.Componente) }}</span>
            </template>
            <!-- Confirmada -->
            <template v-slot:item.ConsumoPor="{ item }">
                <v-chip
                    size="default"
                    :color="item.ConsumoPor == 0  ? 'success' : 'error'"
                    dark>
                    {{ item.ConsumoPor == 0 ? 'Sim' : 'Não' }}
                </v-chip>
            </template>
        </v-data-table>
    </v-row>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $alert = useAlert();

    const page = ref<number>(1);
    const pageSize = ref<number>(10);

    const props = defineProps(['operation', 'workCenters', 'hoursTypes', 'articles']);

    const components = props.operation.components ? props.operation.components : [];
    const articles = props.articles;

    const headers = ref([
        { title: 'Componente', key: 'Componente' },
        { title: 'Descrição', key: 'Descricao' },
        { title: 'Unidade', key: 'Unidade' },
        { title: 'Qtd. Prevista', key: 'QtPrevista', align: 'end' },
        { title: 'Qtd. Consumida', key: 'QtConsumida', align: 'end' },
        { title: 'Qtd. Unitária', key: 'QtUnitaria', align: 'end' },
        { title: 'Qtd. Por', key: '', align: 'end' },
        { title: 'Desperdício', key: 'Desperdicio', align: 'end' },
        { title: 'Consumo por', key: 'ConsumoPor' },
        { title: 'Preço', key: 'Preco', align: 'end' },
        { title: 'Observações', key: 'Observacoes' },
    ]);

    async function onRowClick(row: any, o: any) {
        console.log('on row click');
        // $router.push({ name: 'OperationDetails', params: {id: $route.params.id,  operationId: o.item.IDOrdemFabricoOperacao } });
    }

    // Return "Descrição Componente"
    const description = (id: number) => {
        return articles?.find((a: any) => a.Artigo == id)?.Descricao ?? '';
    };

    // Return "Unidade Componente"
    const unit = (id: number) => {
        return articles?.find((a: any) => a.Artigo == id)?.UnidadeBase ?? '';
    };
</script>

<style scoped></style>
