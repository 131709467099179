import { colord } from 'colord';

const colors = createVariations({
    primary: '#F75E25',
    secondary: '#414246',
    red: {
        100: '#fee2e2',
        200: '#fecaca',
        300: '#fca5a5',
        400: '#f87171',
        DEFAULT: '#ef4444',
        600: '#dc2626',
        700: '#b91c1c',
        800: '#991b1b',
        900: '#7f1d1d',
    },
    green: {
        100: '#d1fae5',
        200: '#a7f3d0',
        300: '#6ee7b7',
        400: '#34d399',
        DEFAULT: '#10b981',
        600: '#059669',
        700: '#047857',
        800: '#065f46',
        900: '#064e3b',
    },
    gray: {
        100: '#F9F9F9',
        200: '#f3f4f6',
        300: '#e5e7eb',
        400: '#d1d5db',
        DEFAULT: '#9ca3af',
        600: '#6b7280',
        700: '#4b5563',
        800: '#374151',
        900: '#1f2937',
    },
});

export const theme = {
    colors,
    fontFamily: {
        // sans: ['roboto', 'sans-serif'],
        sans: ['Inter', 'sans-serif'],
    },
} as any;

export default theme;

function createVariations(colors: any) {
    const variations: any = {};
    for (const color in colors) {
        if (['transparent', 'current', 'black', 'white'].includes(color)) {
            variations[color] = colors[color];
            continue;
        }
        if (typeof colors[color] === 'object') {
            for (const shade in colors[color]) {
                if (shade.toUpperCase() != 'DEFAULT') variations[`${color}-${shade}`] = colors[color][shade];
                else variations[color] = colors[color][shade];
            }
            continue;
        }

        variations[color] = colors[color];
        const col = colord(colors[color]);
        for (let i = 0.05; i <= 0.2; i += 0.05) {
            variations[`${color}-${Math.abs(Math.round(i * 2000 - 500))}`] = col.lighten(i).toHex();
        }
        for (let i = 0.05; i <= 0.2; i += 0.05) {
            variations[`${color}-${Math.abs(Math.round(i * 2000 + 500))}`] = col.darken(i).toHex();
        }
    }
    return variations;
}

export function vuetifyColors() {
    const vuetifyColors: any = {};
    for (const color in theme.colors) {
        if (['transparent', 'current'].includes(color)) continue;
        vuetifyColors[color] = theme.colors[color];
    }
    return vuetifyColors;
}

export function tailwindColors() {
    const tailwindColors: any = {};
    for (const color in theme.colors) {
        if (['transparent', 'current'].includes(color)) {
            tailwindColors[color] = theme.colors[color];
            continue;
        }
        tailwindColors[color] = `rgba(var(--v-theme-${color}), <alpha-value>)`;
    }

    return tailwindColors;
}
