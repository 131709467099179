<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">{{ $t('internalDocuments.RI.title') }}</h1>
            </v-col>
        </v-row>

        <v-form
            ref="form"
            @submit.prevent="onSubmit">
            <v-row>
                <v-col cols="12">
                    <p class="tw-mb-2 tw-text-lg tw-font-bold">{{ $t('internalDocuments.RI.subtitle') }}</p>
                </v-col>
            </v-row>
            <v-row class="tw-mt-3">
                <v-col
                    cols="12"
                    md="6"
                    lg="6">
                    <v-select
                        v-model="data.serie"
                        label="Tipo"
                        outlined
                        :items="series"
                        dense
                        clearable
                        :rules="[rules.required]" />
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    lg="6">
                    <v-text-field
                        type="date"
                        v-model="data.documentDate"
                        label="Data do Documento"
                        outlined
                        dense
                        clearable
                        :rules="[rules.required]" />
                </v-col>
            </v-row>
            <v-divider class="tw-mt-3"></v-divider>
            <v-row class="tw-mt-3">
                <!-- <v-col cols="12">
                    <p class="tw-mb-2 tw-text-lg tw-font-bold">Artigos</p>
                </v-col> -->
                <v-col>
                    <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                        <hot-table
                            :data="data.articles"
                            :licenseKey="license"
                            :contextMenu="true"
                            :columns="columns"
                            :rowHeaders="false"
                            :stretchH="'all'"
                            :width="'100%'"
                            :colHeaders="tableHeders"
                            :beforeKeyDown="handleBeforeKeyDown"
                            ref="hotTableComponent"></hot-table>
                    </div>
                </v-col>

                <!-- <v-col cols="12">
                    <v-text-field
                        v-model="data.articles"
                        label="Artigos"
                        outlined
                        dense
                        clearable
                        :rules="[rules.required]" />
                </v-col> -->
            </v-row>
            <v-row>
                <v-col class="tw-flex tw-items-end tw-justify-end">
                    <v-btn
                        color="primary"
                        type="submit"
                        >{{ $t('shared.save') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed, nextTick } from 'vue';
    import useRules from '@/composables/rules';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { getInternalSeriesByDocType } from '@/api/internalSeries';
    import { getAllProjects } from '@/api/projects';
    import { createInternalDocument } from '@/api/internalDocuments';
    import moment from 'moment'; // Import moment for date parsing

    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';
    import Handsontable from 'handsontable';
    import { getArticles } from '@/api/articles';

    registerAllModules();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);
    const hotTableComponent = ref(null);

    const rules = useRules();
    const $alert = useAlert();
    const { showLoader, hideLoader } = useLoader();

    const series = ref([]);
    const projects = ref([]);
    const articles = ref([]);

    const tableHeders = ref(['Artigo', 'Quantidade', 'Data de Entrega', 'Projeto']);

    const done = ref(false);
    const form = ref(null);

    const createDropdownSource = (array: any, key: any) => (done.value ? array.value.map((item: any) => item[key]) : []);
    const columns = computed(() => {
        return [
            {
                data: 'article',
                type: 'dropdown',
                source: createDropdownSource(articles, 'Artigo'),
                validator: function (value, callback) {
                    // Check if the value is empty (required field)
                    callback(!!value); // Returns true if value is not empty
                },
                // allowInvalid: false, // Prevent saving invalid data
            },
            {
                data: 'quantity',
                type: 'numeric',
                format: '0,0.00',
                validator: function (value, callback) {
                    // Ensure quantity is not null and greater than 0
                    callback(value != null && value > 0);
                },
                allowInvalid: false,
            },
            {
                data: 'date',
                type: 'date',
                dateFormat: 'DD/MM/YYYY', // Use DD/MM/YYYY format for date column
                correctFormat: true, // Correct any wrongly formatted date entered by user
                //allowInvalid: false,
                validator: function (value, callback) {
                    // Ensure the date is in DD/MM/YYYY format and valid
                    const isValidDate = moment(value, 'DD/MM/YYYY', true).isValid();
                    callback(isValidDate);
                },
            },
            {
                data: 'project',
                type: 'dropdown',
                source: createDropdownSource(projects, 'Codigo'),
                validator: function (value, callback) {
                    // check if value is in projects array
                    callback(projects.value.some((project: any) => project.Codigo === value));
                },
            },
        ];
    });

    const init = async () => {
        showLoader();
        try {
            const [seriesResponse, projectsResponse] = await Promise.all([getInternalSeriesByDocType('RI'), getAllProjects()]);

            series.value = seriesResponse.data.map((item: any) => item.Serie);
            console.log(series.value);
            console.log(projectsResponse);
            projects.value = projectsResponse.data as any;

            done.value = true;

            console.log(projects.value);
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao obter séries internas, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    const data = ref({
        serie: null,
        typeDoc: 'RI',
        documentDate: new Date().toISOString().substring(0, 10),
        articles: [
            {
                article: '',
                quantity: 1,
                date: new Date().toLocaleDateString(),
                project: '',
                project_id: '',
            },
        ],
    });

    const onSubmit = async () => {
        if (!form.value) return;
        const { valid } = await form.value.validate();
        if (!valid) return;

        try {
            const hotInstance = hotTableComponent.value.hotInstance; // Access Handsontable instance via ref

            // Trigger Handsontable validation
            hotInstance.validateCells((valid) => {
                if (valid) {
                    // If validation passes, proceed with form submission
                    showLoader();

                    // Set project id based on the selected project in each article
                    data.value.articles.forEach((article: any) => {
                        const project = projects.value.find((p: any) => p.Codigo === article.project);
                        article.project_id = project.ID;
                        article.date = moment(article.date, 'DD/MM/YYYY').format('YYYY-MM-DD');
                    });

                    console.log(data.value);

                    createInternalDocument(data.value)
                        .then(() => {
                            $alert.showAlert({
                                type: 'success',
                                text: 'Ordem de Serviço Interna criada com sucesso.',
                            });
                        })
                        .catch((error) => {
                            $alert.showAlert({
                                type: 'error',
                                text: 'Erro ao criar Ordem de Serviço Interna, tente novamente.',
                            });
                        })
                        .finally(() => hideLoader());
                } else {
                    // If validation fails, show an error message
                    $alert.showAlert({
                        type: 'error',
                        text: 'Preencha todos os campos obrigatórios corretamente antes de enviar.',
                    });
                }
            });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao criar Ordem de Serviço Interna, tente novamente.',
            });
        }
    };

    // Handle beforeKeyDown event
    const handleBeforeKeyDown = (event) => {
        const hotInstance = hotTableComponent.value.hotInstance; // Access Handsontable instance via ref
        const activeEditor = hotInstance.getActiveEditor();
        const selectedRange = hotInstance.getSelectedRange();
        const activeColumn = selectedRange?.[0]?.from?.col;

        const isPrintableCharacter = event.key.length === 1 && event.key.match(/^[\w\d\s\p{L}\p{N}]$/u);

        // Assuming 'article' field is in column 0
        if (activeColumn === 0 && isPrintableCharacter) {
            const currentValue = activeEditor?.TEXTAREA?.value || '';
            const newValue = currentValue + event.key;

            if (newValue.length > 3) {
                console.log('Custom function triggered:', newValue);
                searchArticles(newValue);
            }
        }
    };

    const searchArticles = async (article: string) => {
        try {
            const response = await getArticles(null, null, article, null);
            articles.value = response.data.data;

            // Trigger Handsontable to update the dropdown source dynamically
            // const hotInstance = hotTableComponent.value.hotInstance;
            // hotInstance.getPlugin('autocompleter').updateDropdownSource(hotInstance);
            console.log('Dropdown updated with:', articles.value);
        } catch (error) {
            console.error('Error fetching articles:', error);
        }
    };

    init();
</script>

<style scoped>
    .hot-table-container {
        height: 300px;
    }
</style>
