<template>
    <v-container
        v-if="done"
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">{{ $t('internalDocuments.OSI.internalServiceOrder.title') }}: {{ internalRequest.document }}</h1>
            </v-col>
        </v-row>

        <v-row class="tw-mt-3">
            <v-col
                cols="12"
                md="6"
                lg="6">
                <v-text-field
                    readO
                    type="text"
                    v-model="internalRequest.serie"
                    label="Série"
                    outlined
                    readonly
                    dense
                    clearable
                    :rules="[rules.required]" />
            </v-col>
            <v-col
                cols="12"
                md="6"
                lg="6">
                <v-text-field
                    type="date"
                    v-model="internalRequest.documentDate"
                    label="Data do Documento"
                    outlined
                    readonly
                    dense
                    clearable
                    :rules="[rules.required]" />
            </v-col>
        </v-row>
        <v-divider class="tw-mt-3"></v-divider>
        <v-row class="tw-mt-3">
            <v-col>
                <div class="hot-table-container tw-w-full tw-overflow-x-auto">
                    <hot-table
                        :readOnly="true"
                        :data="internalRequest.articles"
                        :licenseKey="license"
                        :contextMenu="true"
                        :columns="columns"
                        :rowHeaders="false"
                        :stretchH="'all'"
                        :width="'100%'"
                        :colHeaders="tableHeders"
                        ref="hotTableComponent"></hot-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import useRules from '@/composables/rules';

    import { getInternalDocument } from '@/api/internalDocuments';
    import usePrimavera from '@/composables/primaveraParser';

    // imports to use handsontable
    import 'handsontable/dist/handsontable.full.min.css';
    import 'handsontable/dist/handsontable.full.css';
    import { HotTable } from '@handsontable/vue3';
    import { registerAllModules } from 'handsontable/registry';

    registerAllModules();
    const license = ref(import.meta.env.VITE_APP_HANDSONTABLE_LICENSE_KEY);
    const hotTableComponent = ref(null);

    const { parsePrimaveraInternalDocumentToSoc } = usePrimavera();
    const rules = useRules();

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $router = useRouter();
    const $alert = useAlert();

    const internalRequest = ref(null) as any;
    const done = ref(false);

    const tableHeders = ref(['Artigo', 'Quantidade', 'Data Entrega', 'Projeto']);

    const columns = computed(() => {
        return [
            {
                data: 'article',
                type: 'text',
                //source: createDropdownSource(articles, 'Artigo'),
                // validator: function (value, callback) {
                //     // Check if the value is empty (required field)
                //     callback(!!value); // Returns true if value is not empty
                // },
                // allowInvalid: false, // Prevent saving invalid data
            },
            {
                data: 'quantity',
                type: 'numeric',
                format: '0,0.00',
                // validator: function (value, callback) {
                //     // Ensure quantity is not null and greater than 0
                //     callback(value != null && value > 0);
                // },
                // allowInvalid: false,
            },
            {
                data: 'deliveryDate',
                type: 'date',
                dateFormat: 'DD/MM/YYYY', // Use DD/MM/YYYY format for date column
                correctFormat: true, // Correct any wrongly formatted date entered by user
                //allowInvalid: false,
                // validator: function (value, callback) {
                //     // Ensure the date is in DD/MM/YYYY format and valid
                //     const isValidDate = moment(value, 'DD/MM/YYYY', true).isValid();
                //     callback(isValidDate);
                // },
            },
            {
                data: 'project',
                type: 'text',
                // source: createDropdownSource(projects, 'Codigo'),
                // validator: function (value, callback) {
                //     // check if value is in projects array
                //     callback(projects.value.some((project: any) => project.Codigo === value));
                // },
            },
        ];
    });

    const init = async () => {
        showLoader();
        try {
            const response = await getInternalDocument($route.params.id as string);
            internalRequest.value = parsePrimaveraInternalDocumentToSoc(response.data);
            console.log(internalRequest.value);
            done.value = true;
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar a requisição interna',
            });
        } finally {
            hideLoader();
        }
    };

    init();
</script>

<style scoped>
    .hot-table-container {
        height: 300px;
    }
</style>
