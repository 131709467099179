<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">Novo Cálculo de Necessidades</h1>
            </v-col>
        </v-row>

        <v-form
            ref="form"
            @submit.prevent="onSubmit">
            <v-row class="tw-mt-3">
                <v-col cols="12">
                    <h1 class="tw-text-xl tw-font-bold">Dados Gerais</h1>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    xl="12"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <!-- <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.rangeDates') }}
                    </v-label> -->
                    <div>
                        <v-date-picker
                            multiple="range"
                            title=""
                            show-adjacent-months
                            v-model="data.rangeDates"></v-date-picker>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.code') }}
                    </v-label>
                    <div>
                        <v-text-field
                            :maxlength="10"
                            :minLength="1"
                            :placeholder="$t('calcNeeds.fields.code')"
                            :rules="[rules.required]"
                            v-model="data.code"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.description') }}
                    </v-label>
                    <div>
                        <v-text-field
                            :placeholder="$t('calcNeeds.fields.description')"
                            :rules="[rules.required]"
                            v-model="data.description"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.needType') }}
                    </v-label>
                    <div>
                        <v-select
                            :rules="[rules.required]"
                            :items="typeNeeds"
                            :placeholder="$t('calcNeeds.fields.needType')"
                            item-title="text"
                            v-model:model-value="data.needType"
                            item-value="value">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.minStock') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle

                            mandatory
                            :rules="data.needType != 'tnCNBrutas' ? [rules.required] : []"
                            :disabled="data.needType != 'tnCNLiquidas'"
                            color="primary"
                            v-model="data.minStock"
                            border
                            class="tw-mt-auto">
                            <v-btn  :value="true"> {{ $t('shared.yes') }} </v-btn>
                            <v-btn  :value="false"> {{ $t('shared.no') }} </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.needsOrigin') }}
                    </v-label>
                    <div>
                        <v-select
                            :rules="[data.needsOrigin.length > 0]"
                            :items="needsOrigin"
                            :placeholder="$t('calcNeeds.fields.needsOrigin')"
                            item-title="text"
                            v-model:model-value="data.needsOrigin"
                            multiple
                            item-value="value">
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="tw-flex tw-items-end tw-justify-end">
                    <v-btn
                        color="primary"
                        type="submit"
                        >{{ $t('shared.save') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import useRules from '@/composables/rules';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { createCalcNeed } from '@/api/calcNeeds';
    import { CalcNeed } from '@/types';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();

    const rules = useRules();
    const data = ref({
        code: '',
        description: '',
        date: new Date(),
        rangeDates: [] as Date[],
        needType: null,
        minStock: null,
        group: '',
        needsOrigin: [] as string[],
    });

    const typeNeeds = ref([
        { value: 'tnCNLiquidas', text: 'Líquidas' },
        { value: 'tnCNBrutas', text: 'Brutas' },
    ]);

    const needsOrigin = ref([
        { value: 'orders', text: 'Encomendas' },
        { value: 'productionPlans', text: 'Planos de Produção' },
    ]);

    const onSubmit = async () => {
        console.log(data.value);

        const dataToSend: CalcNeed = {
            CalculoNecessidades: data.value.code,
            Descricao: data.value.description,
            Data: data.value.date.toISOString(),
            TipoNecessidade: data.value.needType,
            DataIni: data.value.rangeDates.length > 0 ? data.value.rangeDates[0].toISOString() : null,
            DataFim: data.value.rangeDates.length > 0 ? data.value.rangeDates[data.value.rangeDates.length - 1].toISOString() : null,
            ...(data.value.minStock != null && { StockMinimo: data.value.minStock }),
            EmModoEdicao: false,
            OrigemEmEncomendas: (function () {
                if (data.value.needsOrigin.includes('orders')) return true;
                return false;
            })(),
            OrigemEmPlanosProducao: (function () {
                if (data.value.needsOrigin.includes('productionPlans')) return true;
                return false;
            })(),
            OrigemEmOrdensFabrico: false,
        };

        try {
            showLoader();
            const response = await createCalcNeed(dataToSend);
            $alert.showAlert({
                type: 'success',
                text: 'Cálculo de Necessidades criado com sucesso.',
            });

            console.log(response);
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao criar o cálculo de necessidades. Por favor, tente novamente.',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };
</script>

<style scoped></style>
