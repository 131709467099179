import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/internal-documents';



export async function createInternalDocument(internalDocument: any): AxiosPromise<any> {
    return axios.post(`${API_REF}`, internalDocument);
}

export async function getInternalDocumentsByDocType(docType: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/type/${docType}`);
}

export async function getInternalDocument(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}
