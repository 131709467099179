<template>
    <v-navigation-drawer v-model="isOpen">
        <!-- <v-list-item
            prepend-avatar="https://randomuser.me/api/portraits/men/78.jpg"
            title="SUCOREMA"></v-list-item> -->

        <v-img
            src="@/assets/images/logo-stefa.svg"
            class="logo tw-m-auto"
            width="120"
            aspect-ratio="1"
            alt="Stefa Logo"></v-img>
        <!-- <v-divider></v-divider> -->
        <v-list
            density="compact"
            nav>
            <v-list-item
                :title="$t('panel.title')"
                :to="{ name: 'Home' }"></v-list-item>
            <v-list-item
                color="primary"
                :title="$t('workCenters.title')"
                :to="{ name: 'WorkCenters' }"></v-list-item>
            <v-list-group value="Articles">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('articles.title')"></v-list-item>
                </template>
                <v-list-item
                    color="primary"
                    :title="$t('articles.listArticles')"
                    :to="{ name: 'Articles' }"></v-list-item>
                <v-list-item
                    color="primary"
                    :title="$t('articles.loadArticles')"
                    :to="{ name: 'LoadArticles' }"></v-list-item>
            </v-list-group>
            <v-list-group value="TechnicalSheets">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('techincalSheets.nav')"></v-list-item>
                </template>
                <v-list-item
                    color="primary"
                    :title="$t('techincalSheets.listTechnicalSheets')"
                    :to="{ name: 'TechnicalSheets' }"></v-list-item>
                <v-list-item
                    v-if="authStore.user?.role?.name == 'Project' || authStore.user?.role?.name == 'Authenticated'"
                    color="primary"
                    :title="$t('techincalSheets.loadInventorTechnicalSheets')"
                    :to="{ name: 'LoadInventorTechnicalSheet' }"></v-list-item>
            </v-list-group>


            <v-list-group value="InternalDocuments">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('internalDocuments.nav')"></v-list-item>
                </template>
                <v-list-item
                    color="primary"
                    :title="$t('internalDocuments.RI.title')"
                    :to="{ name: 'InternalRequests' }"></v-list-item>
                <v-list-item
                    color="primary"
                    :title="$t('internalDocuments.OSI.title')"
                    :to="{ name: 'InternalOrderServices' }"></v-list-item>
            </v-list-group>
            <v-list-item
                color="primary"
                :title="$t('calcNeeds.nav')"
                :to="{ name: 'CalcNeeds' }"></v-list-item>
            <v-list-group value="ProductionOrders">
                <template v-slot:activator="{ props }">
                    <v-list-item
                        color="primary"
                        v-bind="props"
                        :title="$t('productionOrders.nav')"></v-list-item>
                </template>
                <v-list-item
                    color="primary"
                    :title="$t('productionOrders.listProductionOrders')"
                    :to="{ name: 'ProductionOrders' }"></v-list-item>
                <v-list-item
                    v-if="authStore.user?.role?.name == 'Authenticated'"
                    color="primary"
                    :title="$t('productionOrders.operations.operationsList')"
                    :to="{ name: 'Operations' }"></v-list-item>
                <v-list-item
                    v-if="authStore.user?.role?.name == 'Authenticated'"
                    color="primary"
                    :title="$t('productionOrders.controlPanel')"
                    :to="{ name: 'ControlPanel' }"></v-list-item>
            </v-list-group>
            <!--
            <v-list-item
                link
                prepend-icon="mdi-star"
                title="Starred"></v-list-item> -->
        </v-list>
        <template v-slot:append>
            <div class="tw-p-2">
                <v-btn
                    @click="logout"
                    block
                    color="black">
                    {{ $t('auth.logout') }}
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script setup lang="ts">
    import { ref, watch, onBeforeUnmount } from 'vue';
    import { useAppStore } from '../store/app';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';

    const appStore = useAppStore();
    const authStore = useAuthStore();
    const $router = useRouter();

    const isOpen = ref(true);

    watch(
        () => appStore.isSidebarOpen,
        (value) => {
            isOpen.value = value;
        },
    );

    watch(
        () => isOpen.value,
        (value) => {
            appStore.setIsSidebarOpen(value);
        },
    );

    onBeforeUnmount(() => {
        appStore.setIsSidebarOpen(false);
    });

    async function logout() {
        authStore.$reset();
        $router.push({ name: 'Login' });
    }
</script>

<style>
    .logo img,
    .v-navigation-drawer__content img {
        display: block !important;
    }

    .v-list-group__items {
        height: auto !important;
    }
</style>
