import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';
import { CalcNeed } from '@/types';

export const API_REF = 'primavera/calc-needs';

export async function getCalcNeeds(): AxiosPromise<CalcNeed[]> {
    return axios.get(API_REF);
}

export async function getCalcNeed(id: string): AxiosPromise<CalcNeed> {
    return axios.get(`${API_REF}/${id}`);
}

export async function createCalcNeed(calcNeed: CalcNeed): AxiosPromise<CalcNeed> {
    return axios.post(API_REF, calcNeed);
}


export async function updateCalcNeed(id: number, calcNeed: CalcNeed): AxiosPromise<CalcNeed> {
    return axios.put(`${API_REF}/${id}`, calcNeed);
}

export async function deleteCalcNeed(id: number): AxiosPromise<any> {
    return axios.delete(`${API_REF}/${id}`);
}

export async function processCalcNeed(calcNeed: CalcNeed): AxiosPromise<CalcNeed> {
    return axios.post(`${API_REF}/process`, calcNeed);
}

export async function getProcessedCalcNeed(): AxiosPromise<CalcNeed[]> {
    return axios.get(`${API_REF}/processed`);
}
