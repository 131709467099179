<template>
    <v-form
        ref="form"
        @submit.prevent="onSubmit">
        <v-row class="tw-mt-3">
            <v-col cols="12">
                <h1 class="tw-text-xl tw-font-bold">Dados Gerais</h1>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.timeUnit') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.operations.fields.timeUnit')"
                        :rules="[rules.required]"
                        v-model="operation.UnidadeTempo"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="8"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg"> </v-label>
                <div>
                    <v-text-field v-model="timeUnit"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.noPlanning') + '?' }}
                </v-label>
                <div>
                    <v-btn-toggle
                        mandatory
                        :rules="[rules.required]"
                        color="primary"
                        v-model="operation.NaoSujeitaPlaneamento"
                        border
                        class="tw-mt-auto">
                        <v-btn :value="true"> {{ $t('shared.yes') }} </v-btn>
                        <v-btn :value="false"> {{ $t('shared.no') }} </v-btn>
                    </v-btn-toggle>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.overlapCoefficient') + '?' }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.operations.fields.overlapCoefficient')"
                        :rules="[rules.required]"
                        suffix="%"
                        v-model="operation.CoeficienteSobreposicao"></v-text-field>
                </div>
            </v-col>
            <!-- Centro de Controlo -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('productionOrders.operations.fields.workCenters') }}: </v-label>
            </v-col>
            <v-data-table
                :headers="headers"
                :items="productionOrderWorkCenters"
                v-model:page="page"
                v-model:items-per-page="pageSize"
                @click:row="onRowClick"
                item-value="Componente">
                <!-- Principal -->
                <template v-slot:item.Principal="{ item }">
                    <v-chip
                        size="default"
                        :color="isWorkCenterMain(item.IDCentroTrabalho) ? 'success' : 'error'"
                        dark>
                        {{ isWorkCenterMain(item.IDCentroTrabalho) ? 'Sim' : 'Não' }}
                    </v-chip>
                </template>
                <!-- Centro Trabalho -->
                <template v-slot:item.CentroTrabalho="{ item }">
                    <span>{{ workCenter(item.IDCentroTrabalho).name }}</span>
                </template>
                <!-- Descrição -->
                <template v-slot:item.Descricao="{ item }">
                    <span>{{ workCenter(item.IDCentroTrabalho).description }}</span>
                </template>
                <!-- Tempos Totais (Operação) -->
                <template v-slot:item.TempoOperacao="{ item }">
                    <span>{{ formatTime(item.TempoOperacao) }}</span>
                </template>
                <!-- Tempos Totais (Máquina) -->
                <template v-slot:item.TempoMaquina="{ item }">
                    <span>{{ formatTime(item.TempoMaquina) }}</span>
                </template>
                <!-- Tempos Totais (Mão de Obra) -->
                <template v-slot:item.TempoOperador="{ item }">
                    <span>{{ formatTime(item.TempoOperador) }}</span>
                </template>
                <!-- Outros Tempos (Preparação) -->
                <template v-slot:item.TempoPreparacao="{ item }">
                    <span>{{ formatTime(item.TempoPreparacao) }}</span>
                </template>
                <!-- Outros Tempos (Espera) -->
                <template v-slot:item.TempoEspera="{ item }">
                    <span>{{ formatTime(item.TempoEspera) }}</span>
                </template>
                <!-- Outros Tempos (Movimentação) -->
                <template v-slot:item.TempoMovimentacao="{ item }">
                    <span>{{ formatTime(item.TempoMovimentacao) }}</span>
                </template>
            </v-data-table>
            <!-- Tempos Corrigidos -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('productionOrders.operations.fields.correctedTimes') }}: </v-label>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.operation') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.operations.fields.operation')"
                        :rules="[rules.required]"
                        :suffix="$t('productionOrders.operations.fields.hours')"
                        v-model="correctedTime"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.machine') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.operations.fields.operation')"
                        :rules="[rules.required]"
                        :suffix="$t('productionOrders.operations.fields.hours')"
                        v-model="correctedMachineTime"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.operator') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.operations.fields.operator')"
                        :rules="[rules.required]"
                        :suffix="$t('productionOrders.operations.fields.hours')"
                        v-model="correctedLaborTime"></v-text-field>
                </div>
            </v-col>
            <!-- Tempos Consumidos -->
            <v-col
                cols="12"
                class="tw-pb-0">
                <v-label class="tw-text-dark tw-whitespace-normal tw-font-semibold tw-opacity-100 md:tw-text-lg"> {{ $t('productionOrders.operations.fields.consumedTimes') }}: </v-label>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.operation') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.operations.fields.operation')"
                        :rules="[rules.required]"
                        :suffix="$t('productionOrders.operations.fields.hours')"
                        v-model="consumedTime"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.machine') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.operations.fields.operation')"
                        :rules="[rules.required]"
                        :suffix="$t('productionOrders.operations.fields.hours')"
                        v-model="consumedMachineTime"></v-text-field>
                </div>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                xl="4"
                class="tw-flex tw-flex-col tw-justify-between">
                <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                    {{ $t('productionOrders.operations.fields.operator') }}
                </v-label>
                <div>
                    <v-text-field
                        disabled
                        :placeholder="$t('productionOrders.operations.fields.operator')"
                        :rules="[rules.required]"
                        :suffix="$t('productionOrders.operations.fields.hours')"
                        v-model="consumedLaborTime"></v-text-field>
                </div>
            </v-col>
        </v-row>
    </v-form>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import useRules from '@/composables/rules';

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $alert = useAlert();

    const rules = useRules();

    const page = ref<number>(1);
    const pageSize = ref<number>(10);

    const props = defineProps(['operation', 'workCenters', 'hoursTypes', 'timeUnits', 'articles']);

    console.log(props.operation);

    const operation = props.operation;
    const productionOrderWorkCenters = props.operation.workCenters ? props.operation.workCenters : [];
    const timeUnits = props.timeUnits;
    const workCenters = props.workCenters;

    const timeUnit = timeUnits.find((t: any) => t.Unidade === operation.UnidadeTempo)?.Descricao;

    console.log(operation);

    const headers = ref([
        { title: 'Principal', key: 'Principal' },
        { title: 'Centro Trabalho', key: 'CentroTrabalho' },
        { title: 'Descrição', key: 'Descricao' },
        {
            title: 'Tempos Totais',
            align: 'center',
            children: [
                { title: 'Operação', key: 'TempoOperacao', align: 'end' },
                { title: 'Máquina', key: 'TempoMaquina', align: 'end' },
                { title: 'Mão de Obra', key: 'TempoOperador', align: 'end' },
            ],
        },
        {
            title: 'Tempos Unitários',
            align: 'center',
            children: [
                { title: 'Operação', key: 'TempoPrevisto', align: 'end' },
                { title: 'Máquina', key: 'TempoReal', align: 'end' },
                { title: 'Mão de Obra', key: 'Desvio', align: 'end' },
            ],
        },
        {
            title: 'Outros Tempos',
            align: 'center',
            children: [
                { title: 'Preparação', key: 'TempoPreparacao', align: 'end' },
                { title: 'Espera', key: 'TempoEspera', align: 'end' },
                { title: 'Movimentação', key: 'TempoMovimentacao', align: 'end' },
            ],
        },
        { title: 'Fator Ocupação', key: 'FatorOcupacao' },
    ]);

    async function onRowClick(row: any, o: any) {
        console.log('on row click');
        // $router.push({ name: 'OperationDetails', params: {id: $route.params.id,  operationId: o.item.IDOrdemFabricoOperacao } });
    }

    const onSubmit = () => {
        console.log('submit');
    };

    // Return "Tempo Corrigido (Operação)"
    const correctedTime = computed(() => [formatTime(operation.TempoCorrigido)]);

    // Return "Tempo Corrigido (Máquina)"
    const correctedMachineTime = computed(() => [formatTime(operation.TempoMaquinaCorrigido)]);

    // Return "Tempo Corrigido (Mão de Obra)"
    const correctedLaborTime = computed(() => [formatTime(operation.TempoOperadorCorrigido)]);

    // Return "Tempo Consumido (Operação)"
    const consumedTime = computed(() => [formatTime(operation.TempoConsumido)]);

    // Return "Tempo Consumido (Máquina)"
    const consumedMachineTime = computed(() => [formatTime(operation.TempoMaquinaConsumido)]);

    // Return "Tempo Consumido (Mão de Obra)"
    const consumedLaborTime = computed(() => [formatTime(operation.TempoOperadorConsumido)]);

    // Return "Principal" (boolean value)
    const isWorkCenterMain = (id: string) => {
        const main = operation.IDCentroTrabalho;
        return main === id;
    };

    // Return "Centro Trabalho" information
    const workCenter = (workCenterId: any) => {
        const workCenter = workCenters.find((wc: any) => wc.IDCentroTrabalho == workCenterId);
        return workCenter ? { name: workCenter?.CentroTrabalho, description: workCenter?.Descricao } : { name: '', description: '' };
    };

    // Format time (e.g. 180 minutes to 3:00)
    const formatTime = (time: number) => {
        // Round the time value to 2 decimal places
        const roundedTime = parseFloat(time.toFixed(2));
        const hours = Math.floor(roundedTime / 60);
        const minutes = Math.floor(roundedTime % 60);

        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
    };
</script>

<style scoped></style>
