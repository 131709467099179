import { AxiosPromise } from 'axios';
//import { WorkCenter } from '@/types'; // Add the missing import statement
import axios from '@plugins/axios';

export const API_REF = 'primavera/units';

export async function getUnits(): AxiosPromise<any[]> {
    return axios.get(API_REF);
}

export async function getUnit(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}
