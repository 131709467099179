<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">Cálculo de Necessidades</h1>
            </v-col>
            <v-col
                v-if="calcNeed"
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-xl">{{ calcNeed.CalculoNecessidades }}</h1>
            </v-col>
        </v-row>

        <v-form
            ref="form"
            @submit.prevent="onSubmit">
            <v-row class="tw-mt-3">
                <v-col
                    cols="12"
                    sm="12"
                    xl="12"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <!-- <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.rangeDates') }}
                    </v-label> -->
                    <div>
                        <v-date-picker
                            v-if="done"
                            multiple="range"
                            title=""
                            @update:model-value="updatedPicker"
                            :display-value="initialDisplayMonth"
                            show-adjacent-months
                            v-model="rangeDates"></v-date-picker>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.code') }}
                    </v-label>
                    <div>
                        <v-text-field
                            :maxlength="10"
                            :minLength="1"
                            :placeholder="$t('calcNeeds.fields.code')"
                            :rules="[rules.required]"
                            v-model="calcNeed.CalculoNecessidades"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.description') }}
                    </v-label>
                    <div>
                        <v-text-field
                            :placeholder="$t('calcNeeds.fields.description')"
                            :rules="[rules.required]"
                            v-model="calcNeed.Descricao"></v-text-field>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.needType') }}
                    </v-label>
                    <div>
                        <v-select
                            :rules="[rules.required]"
                            :items="typeNeeds"
                            @update:model-value="needTypeUpdated"
                            :placeholder="$t('calcNeeds.fields.needType')"
                            item-title="text"
                            v-model:model-value="needType"
                            item-value="value">
                        </v-select>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.minStock') + '?' }}
                    </v-label>
                    <div>
                        <v-btn-toggle
                            mandatory
                            :rules="needType != 'tnCNBrutas' ? [rules.required] : []"
                            :disabled="needType != 'tnCNLiquidas'"
                            color="primary"
                            v-model="calcNeed.StockMinimo"
                            border
                            class="tw-mt-auto">
                            <v-btn :value="true">
                                {{ $t('shared.yes') }}
                            </v-btn>
                            <v-btn :value="false">
                                {{ $t('shared.no') }}
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    xl="4"
                    class="tw-flex tw-flex-col tw-justify-between">
                    <v-label class="tw-text-dark tw-mb-1 tw-whitespace-normal tw-opacity-100 md:tw-text-lg">
                        {{ $t('calcNeeds.fields.needsOrigin') }}
                    </v-label>
                    <div>
                        <v-select
                            :rules="[needsOrigin_.length > 0]"
                            :items="needsOrigin"
                            :placeholder="$t('calcNeeds.fields.needsOrigin')"
                            item-title="text"
                            v-model:model-value="needsOrigin_"
                            @update:model-value="needsOriginUpdated"
                            multiple
                            item-value="value">
                        </v-select>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="tw-flex tw-items-center tw-justify-end">
                    <v-btn
                        color="primary"
                        type="submit"
                        >{{ $t('shared.save') }}</v-btn
                    >
                    <v-btn
                        color="secondary"
                        class="ml-2"
                        type="submit"
                        @click="isProcessing = true"
                        >{{ $t('calcNeeds.buttons.process') }}</v-btn
                    >
                    <v-btn
                        color="red"
                        @click="deleteCalc"
                        class="ml-2"
                        >{{ $t('shared.delete') }}</v-btn
                    >
                </v-col>
            </v-row>
        </v-form>

        <!-- Pop-up com as necessidades processadas -->
        <v-dialog
            v-model="dialog"
            max-width="1400">
            <v-card>
                <v-card-title>Resumo</v-card-title>
                <v-card-text>
                    <CalcNeedsProcessed
                        :calcNeedsId="calcNeed.IDCalculoNecessidades"
                        :processedCalcNeed="processedCalcNeed"
                        @update:close="closeDialog"
                /></v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script setup lang="ts">
    import { ref, watch, computed, onMounted } from 'vue';
    import useRules from '@/composables/rules';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { getCalcNeed, deleteCalcNeed, updateCalcNeed, processCalcNeed } from '@/api/calcNeeds';
    import CalcNeedsProcessed from './CalcNeedsProcessed.vue';
    import { CalcNeed } from '@/types';
    import { useRouter, useRoute } from 'vue-router';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();
    const $router = useRouter();
    const $route = useRoute();
    const id = useRoute().params.id;
    const done = ref(false);

    const rules = useRules();

    const calcNeed = ref({} as CalcNeed);

    const processedCalcNeed = ref([]);

    const isProcessing = ref(false);

    const dialog = ref(false);

    const typeNeeds = ref([
        { value: 'tnCNLiquidas', text: 'Líquidas' },
        { value: 'tnCNBrutas', text: 'Brutas' },
    ]);

    const needsOrigin = ref([
        { value: 'orders', text: 'Encomendas' },
        { value: 'productionPlans', text: 'Planos de Produção' },
    ]);

    const rangeDates = computed(() => {
        let range = [];
        const start = new Date(calcNeed.value.DataIni);
        const end = new Date(calcNeed.value.DataFim);

        while (start <= end) {
            range.push(new Date(start));
            start.setDate(start.getDate() + 1);
        }
        return range;
    });

    const initialDisplayMonth = computed(() => {
        return rangeDates.value.length ? rangeDates.value[0] : new Date();
    });

    const needType = computed(() => {
        return calcNeed.value.TipoNecessidade == 0 ? 'tnCNLiquidas' : 'tnCNBrutas';
    });

    const needsOrigin_ = computed(() => {
        return [calcNeed.value.OrigemEncomendas ? 'orders' : '', calcNeed.value.OrigemPlanosProducao ? 'productionPlans' : '', ''].filter((item) => item !== '');
    });

    const updatedPicker = (value: any) => {
        calcNeed.value.DataIni = value[0].toISOString();
        calcNeed.value.DataFim = value[value.length - 1].toISOString();
    };

    const needTypeUpdated = (value: any) => {
        calcNeed.value.TipoNecessidade = value == 'tnCNLiquidas' ? 0 : 1;
    };

    const needsOriginUpdated = (value: any) => {
        calcNeed.value.OrigemEncomendas = value.includes('orders');
        calcNeed.value.OrigemPlanosProducao = value.includes('productionPlans');
    };

    const closeDialog = () => {
        dialog.value = false;
    };

    const onSubmit = async () => {
        try {
            showLoader();
            await updateCalcNeed(calcNeed.value.IDCalculoNecessidades as number, calcNeed.value).then(() => {
                if (isProcessing.value) {
                    calcNeed.value = { ...calcNeed.value, OrigemEmEncomendas: calcNeed.value.OrigemEncomendas, OrigemEmPlanosProducao: calcNeed.value.OrigemPlanosProducao, OrigemEmOrdensFabrico: calcNeed.value.OrigemOrdensFabrico };
                    //process calc need
                    processCalcNeed(calcNeed.value)
                        .then((response) => {
                            $alert.showAlert({
                                type: 'success',
                                text: 'Cálculo de Necessidades processado com sucesso.',
                            });
                            processedCalcNeed.value = response.data;
                        })
                        .finally(() => {
                            isProcessing.value = false;
                            hideLoader();
                            dialog.value = true;
                        });
                } else {
                    $alert.showAlert({
                        type: 'success',
                        text: 'Cálculo de Necessidades atualizado com sucesso.',
                    });
                    hideLoader();
                }
            });
            //$router.push({ name: 'CalcNeeds'});
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao criar o cálculo de necessidades. Por favor, tente novamente.',
            });
            console.error(error);
            hideLoader();
        }
    };

    const deleteCalc = async () => {
        try {
            showLoader();
            const response = await deleteCalcNeed(calcNeed.value.IDCalculoNecessidades as number);
            $alert.showAlert({
                type: 'success',
                text: 'Cálculo de Necessidades eliminado com sucesso.',
            });
            $router.push({ name: 'CalcNeeds' });
        } catch (error) {
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao eliminar o cálculo de necessidades. Por favor, tente novamente.',
            });
            console.error(error);
        } finally {
            hideLoader();
        }
    };

    const init = async () => {
        try {
            showLoader();
            const response = await getCalcNeed(id as string);
            //data.value = response.data;
            console.log(response.data);

            calcNeed.value = response.data;
            done.value = true;
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar os dados. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    };

    init();
</script>

<style scoped></style>
