<template>
    <v-container
        v-if="done"
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botões -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="12"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">{{ operation.OperacaoProducao }} | {{ operation.Operacao }} - {{ operation.Descricao }}</h1>
            </v-col>
            <v-col
                cols="12"
                sm="12"
                class="tw-gap-2 max-lg:tw-grid max-lg:tw-grid-cols-2 md:tw-grid-cols-4 lg:tw-flex">
                <v-btn

                    v-for="(item, index) in navItems"
                    :key="index"
                    :to="item.to"
                    min-width="128"
                    size="x-large"
                    :color="item.to.name == $route.name ? 'primary' : 'gray-200'"
                    >{{ item.title }}</v-btn
                >
            </v-col>
            <!-- <v-col
                v-if="isLoading"
                cols="12">
                <v-progress-linear
                    :active="isLoading"
                    color="primary"
                    indeterminate></v-progress-linear>
            </v-col> -->
        </v-row>
        <router-view
            :operation="operation"
            :workCenters="workCenters"
            :hoursTypes="hoursTypes"
            :timeUnits="timeUnits"
            :articles="articles" />
    </v-container>
</template>

<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';

    import { getWorkCenters } from '@/api/workCenters';
    import { getHoursTypes } from '@/api/hoursTypes';
    import { getTimeUnits } from '@/api/timeUnits';
    import { getProductionOrderOperation, getProductionOrders } from '@/api/productionOrders';
    import { getArticles } from '@/api/articles';

    const { showLoader, hideLoader } = useLoader();
    const $route = useRoute();
    const $alert = useAlert();

    const navItems = ref([
        { title: 'Detalhes', to: { name: 'OperationDetails' } },
        { title: 'Componentes', to: { name: 'OperationComponents' } },
    ]);

    const operation = ref<any>(null);
    const productionOrders = ref<any>(null);
    const workCenters = ref<any>(null);
    const hoursTypes = ref<any>(null);
    const timeUnits = ref<any>(null);
    const articles = ref<any>(null);

    const done = ref(false);

    // computed property to get the production order name
    const productionOrderName = computed(() => {
        return productionOrders.value?.find((po: any) => po.IDOrdemFabrico === operation.value?.IDOrdemFabrico)?.OrdemFabrico;
    });

    async function init() {
        showLoader();
        try {
            const operationId = $route.params.operationId;
            const [operationResponse, productionOrdersResponse, workCentersResponse, hoursTypesResponse, timeUnitsResponse] = await Promise.all([
                getProductionOrderOperation(operationId as string),
                getProductionOrders(null, null, null),
                getWorkCenters(null,null,null, null),
                getHoursTypes(),
                getTimeUnits(),
            ]);
            operation.value = operationResponse.data;
            productionOrders.value = productionOrdersResponse.data;
            workCenters.value = workCentersResponse.data;
            hoursTypes.value = hoursTypesResponse.data;
            timeUnits.value = timeUnitsResponse.data;

            // get articles so we can display the description and unit in components table
            if (operation.value?.components) {
                const articlesIds = operation.value?.components?.map((c: any) => c.Componente);
                const filterByIds = {
                    Artigo: articlesIds,
                }
                if (articlesIds) {
                    const articlesResponse = await getArticles(null, null, null, filterByIds);
                    articles.value = articlesResponse.data.data;
                }
            }
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Ocorreu um erro ao carregar os dados das operações da ordem de fabrico.',
            });
        } finally {
            done.value = true;
            hideLoader();
        }
    }

    init();
</script>

<style scoped></style>
