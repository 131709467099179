import { AxiosPromise } from 'axios';
import axios from '@plugins/axios';

export const API_REF = 'primavera/operations';


export async function getOperations(page: number | null = null, limit: number | null = null, search: string | null, filterByIds: { [key: string]: number[] } | null = null): AxiosPromise<any[]> {
    return axios.get(API_REF, {
        params: {
            page,
            limit,
            search,
            filterByIds,
        },
    });
}

export async function getOperation(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

export async function updateOperation(id: string, operation: any): AxiosPromise<any> {
    return axios.put(`${API_REF}/${id}`, operation);
}

export async function deleteOperation(id: string): AxiosPromise<any> {
    return axios.delete(`${API_REF}/${id}`);
}

