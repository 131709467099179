import { AxiosPromise } from 'axios';
//import { WorkCenter } from '@/types'; // Add the missing import statement
import axios from '@plugins/axios';

export const API_REF = 'articles-upload-template';


export async function getArticlesUploadTemplate(): AxiosPromise<any[]> {
    return axios.get(API_REF);
}


