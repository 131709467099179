<template>
    <div
        v-if="props.articleOperations?.length > 0"
        class="tw-mt-3">
        <v-row>
            <v-col class="tw-flex tw-items-end tw-justify-between">
                <p class="tw-text-xl tw-font-semibold">Operações</p>
            </v-col>
        </v-row>
        <v-row>
            <v-expansion-panels
                class="tw-mb-3"
                v-for="operation in props.articleOperations">
                <v-expansion-panel>
                    <v-expansion-panel-title>
                        <v-row>
                            <v-col class="tw-flex tw-items-end tw-justify-between">
                                <p class="tw-text-xl">{{ operation.OperacaoProducao }} - {{ operation.Descricao }} ( {{ operation.Operacao }} )</p>
                                <v-chip>{{ operation.Componentes?.length }} Componentes</v-chip>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <v-row>
                            <v-col>
                                <p class="tw-text-l tw-font-semibold">Componentes:</p>
                            </v-col>
                        </v-row>
                        <v-data-table
                            :hover="true"
                            @click:row="handleClick"
                            :items="operation.Componentes"></v-data-table>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </div>
</template>

<script setup lang="ts">
    import { ref, watch } from 'vue';
    import { useLoader } from '@/composables/useLoader';
    import { defineProps, defineEmits } from 'vue';
    import { useAlert } from '@/composables/useAlert';
    import { useRoute } from 'vue-router';

    const props = defineProps(['article', 'families', 'articleTypes', 'units', 'articleOperations']);
    const emit = defineEmits(['drilldown:article']);
    const $alert = useAlert();
    const $route = useRoute();

    const onSubmit = () => {
        console.log('submit');
    };

    const init = () => {
        if (props.articleOperations?.length == 0) {
            // show alert
            $alert.showAlert({
                type: 'warning',
                text: 'Não existem operações para este artigo',
            });
        }
    };

    init();


    const handleClick = (event: any, row: any) => {
        console.log('Clicked item: ', row.item);
        emit('drilldown:article', row.item.Componente);
    };
</script>

<style scoped>
    /* .v-expansion-panel-title--active .v-expansion-panel-text {
    display: block !important;
}
 */

    .v-expansion-panel-text {
        display: block !important;
    }

    .hot-table-container {
        height: 85vh;
    }
</style>
