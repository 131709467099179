<template>
    <v-container
        class="flex-column justify-start lg:tw-px-8 lg:tw-pt-12"
        fluid>
        <!-- Título e botão Filtrar -->
        <v-row
            align="start"
            justify="start"
            class="fill-width h-auto justify-start align-content-start">
            <v-col
                cols="9"
                class="tw-flex tw-items-center tw-justify-between">
                <h1 class="tw-text-2xl tw-font-bold">Cálculo de Necessidades</h1>
            </v-col>
            <v-col
                cols="3"
                class="tw-flex tw-items-center tw-justify-end">
                <v-btn

                    color="primary"
                    @click="$router.push({ name: 'CreateCalcNeeds' })"
                    >{{ $t('shared.add') }}</v-btn
                >
            </v-col>
        </v-row>

        <v-row>
            <v-data-table
                :headers="headers"
                :items="calcNeeds"
                @click:row="onRowClick">
                <template v-slot:item.DataIni="{ item }">
                    <span>{{ formatDate(item.DataIni) }}</span>
                </template>
                <template v-slot:item.DataFim="{ item }">
                    <span>{{ formatDate(item.DataFim) }}</span>
                </template>
                <template v-slot:item.DataUltimoCalculo="{ item }">
                    <span>{{ formatDate(item.DataUltimoCalculo) }}</span>
                </template>
                <template v-slot:item.TipoNecessidade="{ item }">
                    <span>{{ needType(item.TipoNecessidade) }}</span>
                </template>
            </v-data-table>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { CalcNeed } from '@/types';
    import { getCalcNeeds } from '@/api/calcNeeds';
    import { ref, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { useLoader } from '@/composables/useLoader';
    import _ from 'lodash';
    import { useAlert } from '@/composables/useAlert';

    const { showLoader, hideLoader } = useLoader();
    const $router = useRouter();
    const $route = useRoute();
    const $alert = useAlert();

    const calcNeeds = ref<CalcNeed[]>([]);

    const headers = ref([
        {
            title: 'Código',
            key: 'CalculoNecessidades',
        },
        {
            title: 'Descrição',
            key: 'Descricao',
        },
        {
            title: 'Início',
            key: 'DataIni',
        },
        {
            title: 'Fim',
            key: 'DataFim',
        },
        {
            title: 'Último Cálculo',
            key: 'DataUltimoCalculo',
        },
        {
            title: 'Necessidades',
            key: 'TipoNecessidade',
        },
    ]);

    async function onRowClick(row: any, o: any) {
        $router.push({ name: 'CalcNeedDetails', params: { id: o.item.CalculoNecessidades } });
    }

    async function init() {
        try {
            showLoader();
            const response = await getCalcNeeds();
            calcNeeds.value = response.data;
        } catch (error) {
            console.error(error);
            $alert.showAlert({
                type: 'error',
                text: 'Erro ao carregar os dados. Por favor, tente novamente.',
            });
        } finally {
            hideLoader();
        }
    }

    init();

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };

    // Return the type of need
    const needType = (type: number) => {
        return type === 0 ? 'Líquidas' : 'Brutas';
    };
</script>

<style scoped></style>
