import { AxiosPromise } from 'axios';
//import { WorkCenter } from '@/types'; // Add the missing import statement
import axios from '@plugins/axios';

export const API_REF = 'inventor-technical-sheet-template';


export async function getInventorTechnicalSheetTemplate(): AxiosPromise<any[]> {
    return axios.get(API_REF);
}


