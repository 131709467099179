import { AxiosPromise } from 'axios';
import { APIResponseMany, APIResponseSingle, ContentType, APIRequestParams, ContentTypesUID } from '@/contentTypes';
import axios from '@plugins/axios';
import qs from 'qs';


export const API_REF = '/technical-sheets';

export async function getTechnicalSheets(query: APIRequestParams<any>): AxiosPromise<any[]> {
    const params = qs.stringify(query);
    return axios.get(`${API_REF}?${params}`);
    //return axios.get(API_REF);
}

export async function getTechnicalSheetById(id: string): AxiosPromise<any> {
    return axios.get(`${API_REF}/${id}`);
}

export async function createTechnicalSheet(technicalSheet: any): AxiosPromise<any> {
    return axios.post(API_REF, { data: technicalSheet } );
}


export async function updateTechnicalSheet(id: string, technicalSheet: any, query: any): AxiosPromise<any> {

    return axios.put(`${API_REF}/${id}?${qs.stringify(query)}`, { data: technicalSheet });
}

export async function deleteTechnicalSheet(id: string): AxiosPromise<any> {
    return axios.delete(`${API_REF}/${id}`);
}

// export async function getWorkCenter(id: number): AxiosPromise<Section> {
//     return axios.get(`${API_REF}/${id}`);
// }
