<template>
    <v-container
        class="flex-column justify-start"
        fluid>
        <v-row>
            <v-data-table
                :headers="headers"
                show-select
                v-model="selectedProcessedCalcNeeds"
                :items="processedCalcNeeds"
                item-value="Artigo">
                <template v-slot:item.DataProp="{ item }">
                    <span>{{ formatDate(item.DataProp) }}</span>
                </template>
                <template v-slot:item.DataNecessidade="{ item }">
                    <span>{{ formatDate(item.DataNecessidade) }}</span>
                </template>
            </v-data-table>
            <v-row>
                <v-col class="tw-flex tw-justify-end tw-pt-4">
                    <v-btn
                        color="primary"
                        class="mr-2"
                        @click="createProductionOrders">
                        Gerar ordens de fabrico
                    </v-btn>
                    <v-btn
                        color="red"
                        @click="closeDialog">
                        Fechar
                    </v-btn>
                </v-col>
            </v-row>
        </v-row>
        <v-row>
            <v-alert
                class="tw-mt-3"
                v-if="errorMessage"
                type="error"
                variant="tonal"
                density="compact"
                >{{ errorMessage }}</v-alert
            >
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import _, { set } from 'lodash';
    import { useLoader } from '@/composables/useLoader';
    import { useAlert } from '@/composables/useAlert';
    import { defineProps, defineEmits } from 'vue';
    import { createProductionOrder } from '@/api/productionOrders';

    const { showLoader, hideLoader } = useLoader();
    const $alert = useAlert();

    const props = defineProps(['calcNeedsId', 'processedCalcNeed', 'dialog']);

    // Close button is in child component (this one)
    // So we need to emit an event to the parent component
    // To close the dialog
    const emit = defineEmits(['update:close']);

    const closeDialog = () => {
        emit('update:close', props.dialog);
    };

    const calcNeedsId = ref(props.calcNeedsId);
    const processedCalcNeeds = ref(props.processedCalcNeed);
    const selectedProcessedCalcNeeds = ref([]);

    const errorMessage = ref('');

    const headers = ref([
        {
            title: 'Artigo',
            key: 'Artigo',
        },
        {
            title: 'Alternativa',
            key: 'Alternativa',
        },
        {
            title: 'Descrição',
            key: 'DescPedido',
        },
        {
            title: 'Família',
            key: 'Familia',
        },
        {
            title: 'Unidade',
            key: 'Unidade',
        },
        {
            title: 'Data Proposta',
            key: 'DataProp',
        },
        {
            title: 'Data Necessidade',
            key: 'DataNecessidade',
        },
        {
            title: 'Qtd. Proposta',
            key: 'QtProposta',
        },
        {
            title: 'Qtd. Necessária',
            key: 'QtNecessaria',
        },
        {
            title: 'Qtd. Stock',
            key: 'QtStock',
        },
        {
            title: 'Qtd. OF',
            key: 'QtOrdemFabrico',
        },
        {
            title: 'Qtd. Reservada',
            key: 'QtReservada',
        },
        {
            title: 'Qtd. Enc. Fornecedores',
            key: 'QtOC',
        },
    ]);

    const createProductionOrders = async () => {
        // Check if there are selected items
        // If not, show an alert
        if (selectedProcessedCalcNeeds.value.length === 0) {
            console.log('No items selected');
            errorMessage.value = 'Por favor, selecione pelo menos um artigo.';
            setTimeout(() => {
                errorMessage.value = '';
            }, 5000);
            return;
        } else {
            try {
                showLoader();
                for (let i = 0; i < selectedProcessedCalcNeeds.value.length; i++) {
                    let foundProcessedCalcNeed = processedCalcNeeds.value.find((item) => item.Artigo === selectedProcessedCalcNeeds.value[i]);
                    await createProductionOrder({
                        calcNeedsId: calcNeedsId.value,
                        article: foundProcessedCalcNeed?.Artigo,
                        alternative: foundProcessedCalcNeed?.Alternativa,
                        quantity: foundProcessedCalcNeed?.QtProposta,
                    });
                }
            } catch (error) {
                console.log(error);
                errorMessage.value = 'Erro ao gerar as ordens de fabrico.';
                setTimeout(() => {
                    errorMessage.value = '';
                }, 5000);
            } finally {
                hideLoader();
                closeDialog();
                $alert.showAlert({
                    type: 'success',
                    text: 'Ordens de fabrico geradas com sucesso.',
                });
            }
        }
    };

    // Format date to 'dd/mm/yyyy'
    const formatDate = (date: string) => {
        return date == null ? '' : new Date(date).toLocaleDateString('pt-PT');
    };
</script>

<style scoped></style>
